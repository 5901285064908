import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { RoleGuard } from '@depot/@common';
import { DepotCommonModule } from '@depot/@common/depot-common.module';
import { DepotComponentsModule } from '@depot/@components/depot-components.module';
import { PriceBookPageGridComponent, PriceBookPageViewComponent } from '@depot/price-book/page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DepotCommonModule,
    DepotComponentsModule,
    RouterModule.forChild([{
      path: '',
      component: PriceBookPageGridComponent,
      canActivate: [RoleGuard],
      data: {
        isNav: false,
        text: 'Price Book',
        icon: 'fa fa-money-check-alt',
        roles: [RoleGuard.Admin]
      },

    },

    {
      path: ':make/:date',
      component: PriceBookPageViewComponent,
      canActivate: [RoleGuard],
      data: {
        isNav: false,
        title: 'Price Book > View',
        icon: 'fa fa-money-check-alt',
        roles: [RoleGuard.Admin]
      },
    },
    ])
  ],
  declarations: [
    PriceBookPageGridComponent,
    PriceBookPageViewComponent,
  ],
  exports: [
    PriceBookPageGridComponent,
    PriceBookPageViewComponent
  ]
})
export class PriceBookPageModule { }
