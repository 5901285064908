<div class="tw-flex tw-flex-col">
  <iframe [src]="pdfId | safe:'url'"
          type="application/pdf"
          class="tw-flex-grow"></iframe>

</div>

<div class="dp-top-fab-container"
     *ngIf="(priceBookPages$ | async) as pages">
  <button type="button"
          [disabled]="pdfId?.length === 0 || (currentPage$ | async) >= pages.length"
          (click)="changePage(1)"
          mat-mini-fab
          matTooltip="Next Page"
          matTooltipPosition="below"
          color="accent">
    <span class="fa fa-arrow-right"></span>
  </button>
  <!--  -->
  <button type="button"
          [disabled]="pdfId?.length === 0 || (currentPage$ | async)<=1"
          (click)="changePage(-1)"
          mat-mini-fab
          matTooltip="Previous Page"
          matTooltipPosition="below"
          color="accent">
    <span class="fa fa-arrow-left"></span>
  </button>

  <div style="font-weight: 900; ">
    <mat-form-field>
      <input matInput
             type="number"
             [disabled]="pdfId?.length === 0"
             min="1"
             style="text-align: right;"
             (change)="changePage($event.target.value - currentPage$.getValue())"
             [max]="pages.length"
             [ngModel]="currentPage$ | async" />
      <span matTextSuffix>/ {{pages.length}}</span>

    </mat-form-field>

  </div>

</div>
