<div class="content mat-elevation-z8">
  <!--  -->
  <mat-expansion-panel #searchPanel
                       expanded="false">
    <mat-expansion-panel-header>
      <mat-panel-title class="filter-panel-title">
        Filters
        <button mat-button
                color="primary"
                (click)="loadData($event)">Search</button>
        <button mat-button
                color="primary"
                (click)="resetFilters($event)">Reset</button>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <ng-template matExpansionPanelContent
                 *ngIf="(cachedFilters$ | async) as cache">

      <mat-form-field>
        <mat-label>Product Lines</mat-label>
        <mat-select [(ngModel)]="cache.line">
          <mat-option value=""> - Any - </mat-option>
          <mat-option *ngFor="let partLine of (partLines$ | async)"
                      [value]="partLine">
            {{partLine}}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Manufacturer</mat-label>
        <mat-select [(ngModel)]="cache.make">
          <mat-option value=""> - Any - </mat-option>
          <mat-option *ngFor="let make of (makes$ | async)"
                      [value]="make">
            {{make}}
          </mat-option>
        </mat-select>
      </mat-form-field>

    </ng-template>
  </mat-expansion-panel>
  <mat-table [dataSource]="dataSource"
             matSort
             [matSortActive]="(cachedFilters$ | async).orderBy?.split(' ')[0]"
             [matSortDirection]="(cachedFilters$ | async).orderBy?.split(' ')[1]"
             (matSortChange)="sortFilters()">

    <ng-container matColumnDef="edit">
      <mat-header-cell mat-header-cell
                       *matHeaderCellDef></mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">
        <a mat-icon-button
           matTooltip="View Book"
           [routerLink]="[row.make, row.date | date:'yyyy-MM-dd']">
          <span class="fa fa-file-alt"></span>
        </a>

      </mat-cell>
    </ng-container>

    <ng-container matColumnDef="partLine">
      <mat-header-cell mat-header-cell
                       *matHeaderCellDef
                       mat-sort-header>Line</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">{{row.partLine}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="make">
      <mat-header-cell mat-header-cell
                       *matHeaderCellDef
                       mat-sort-header>Make</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">{{row.make}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="lineType">
      <mat-header-cell *matHeaderCellDef>Line Type</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">{{row.lineType}}</mat-cell>
    </ng-container>

    <!-- <ng-container matColumnDef="listPrice">
      <mat-header-cell mat-header-cell
                       *matHeaderCellDef
                       mat-sort-header>List Price</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">{{row.listPrice | currency}}</mat-cell>
    </ng-container> -->



    <ng-container matColumnDef="date">
      <mat-header-cell mat-header-cell
                       *matHeaderCellDef
                       mat-sort-header>Date</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">{{row.date | date:'yyyy-MM-dd'}}</mat-cell>
    </ng-container>

    <ng-container matColumnDef="pageCount">
      <mat-header-cell mat-header-cell
                       *matHeaderCellDef
                       mat-sort-header>Page Count</mat-header-cell>
      <mat-cell mat-cell
                *matCellDef="let row">{{row.pageCount}}</mat-cell>
    </ng-container>

    <mat-header-row *matHeaderRowDef="dataSource.columns; sticky: true"
                    class="table-header-primary"></mat-header-row>
    <mat-row *matRowDef="let row; columns: dataSource.columns;"></mat-row>

    <div class="no-data-row"
         *matNoDataRow>
      No price book pages found
    </div>
  </mat-table>

  <mat-paginator [disabled]="dataSource?.isLoading$ | async"
                 (page)="loadData()"
                 [pageSize]="(cachedFilters$ | async)?.takeCount"
                 [pageSizeOptions]="[25, 50, 250, 500]">
  </mat-paginator>
</div>
