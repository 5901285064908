import { HttpErrorResponse } from '@angular/common/http';
import { Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActivatedRoute } from '@angular/router';

import { HelperService } from '@depot/@common';
import { PriceBookRepositoryService } from '@depot/@data';
import { IPriceBookPage } from '@depot/custom';
import { environment } from '@env';

import { BehaviorSubject, catchError, finalize, fromEvent, of } from 'rxjs';

@Component({
  selector: 'depot-price-book-page-view',
  templateUrl: './price-book-page-view.component.html',
  styleUrls: ['./price-book-page-view.component.scss']
})
export class PriceBookPageViewComponent implements OnInit {
  // public priceBookItems$ = new BehaviorSubject<IPriceBookLine[]>(null);
  public priceBookPages$ = new BehaviorSubject<IPriceBookPage[]>(null);
  public imagePath = environment.get_endpoint('pricebook/page/');
  public pdfId = '';
  public currentPage$ = new BehaviorSubject<number>(1);

  constructor(
    private activatedRoute: ActivatedRoute,
    private priceBookRepo: PriceBookRepositoryService,
    private helper: HelperService,
    private destroyRef: DestroyRef,
  ) {

  }

  // public currentMaxPage$ = new BehaviorSubject<number>(0);
  ngOnInit() {
    this.helper.IsGlobalSpinner$.set(true);
    const params = this.activatedRoute.snapshot.params;

    this.priceBookRepo.getPriceBookPages(params['make'], params['date'])
      .pipe(
        catchError((err: HttpErrorResponse) => {
          this.helper.mapErrors(err, null, null, null);
          return of(null);
        }),
        finalize(() => {
          this.helper.IsGlobalSpinner$.set(false);
          this.helper.IsGlobalBar$.set(false);
        })
      )
      .subscribe((priceBookLine: IPriceBookPage[]) => {
        this.priceBookPages$.next(priceBookLine);
        this.changePage(0);
      });

    fromEvent(document, 'keyup')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((x: KeyboardEvent) => {
        x.preventDefault();
        if (x && (x.key === 'ArrowLeft' || x.key === 'ArrowDown')) {
          this.changePage(-1);
        } else if (x && (x.key === 'ArrowRight' || x.key === 'ArrowUp')) {
          this.changePage(1);
        }
      });
  }

  public changePage(increment: number) {
    let newNumber = this.currentPage$.value + increment;
    const pages = this.priceBookPages$.getValue();
    if (pages.length < newNumber) {
      newNumber = pages.length;
    }
    if (newNumber < 1) {
      newNumber = 1;
    }
    const page = pages[newNumber - 1]?.name;
    this.currentPage$.next(newNumber);
    if (!page) {
      this.helper.showMessage(`There is no page ${newNumber} found`);
      return;
    }
    this.pdfId = this.imagePath + page + '#view=FitH&toolbar=0';
  }



}
