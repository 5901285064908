import { DataSource } from '@angular/cdk/table';

import { IPriceBookSearch, PriceBookRepositoryService } from '@depot/@data';
import { IPagedResults, IPriceBookPageGroupModel } from '@depot/custom';

import { BehaviorSubject, finalize, Subject } from 'rxjs';

export class PriceBookPageGridDataSource extends DataSource<IPriceBookPageGroupModel> {
  public isLoading$ = new BehaviorSubject(false);
  public totalRows$ = new BehaviorSubject(0);
  public get filterName() { return 'price_book_page_grid'; }
  public columns = [
    'edit',
    'partLine',
    'make',
    'lineType',
    'date',
    'pageCount',
  ];
  private data$ = new Subject<IPriceBookPageGroupModel[]>();
  constructor(private priceBookRepo: PriceBookRepositoryService) {
    super();

  }
  connect() {
    return this.data$;
  }

  public loadData(filters: IPriceBookSearch) {

    this.isLoading$.next(true);
    this.priceBookRepo.searchPriceBookPages(filters)
      .pipe(
        // catchError(err => of({ items: [] })),
        finalize(() => this.isLoading$.next(false))
      ).subscribe((x: IPagedResults<IPriceBookPageGroupModel>) => {

        this.data$.next(x.items);
        this.totalRows$.next(x.totalCount);
      });
  }

  disconnect(): void {
    this.data$.complete();
    this.isLoading$.next(false);
    this.isLoading$.complete();
    this.totalRows$.complete();
    // this.depotContext.close();
  }

}
